var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tooltip',{attrs:{"top":"","open-delay":"500","disabled":_vm.hideTooltip},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-autocomplete',_vm._g(_vm._b({staticClass:"BSW-select-input",class:{
        onlyValue: !_vm.outlined,
      },attrs:{"id":_vm.label,"rounded":"","dense":"","outlined":_vm.outlined,"hide-no-data":"","aria-autocomplete":_vm.offAutocomplete ? 'off' : 'on',"hide-details":_vm.hideDetails,"value":_vm.value,"label":_vm.label,"height":_vm.multiple && _vm.inputValue !== 0 ? 'auto' : _vm.height,"items":_vm.items,"item-text":_vm.itemText,"item-value":_vm.itemValue,"rules":_vm.rules || _vm.enableRules,"error":_vm.error || !!_vm.errorMessage,"error-messages":_vm.errorMessage,"success":_vm.success,"color":_vm.color,"background-color":_vm.bgColor,"multiple":_vm.multiple,"minHeigth":_vm.minHeight,"disabled":_vm.disabled,"chips":_vm.multiple,"color":"#0097CE","prepend-icon":_vm.prependIcon},on:{"input":function($event){return _vm.updateValue($event)},"blur":function($event){return _vm.blurUpdateValue()}},nativeOn:{"click":function($event){return _vm.$emit('click.native')}},scopedSlots:_vm._u([(!_vm.notClearable && _vm.value !== undefined)?{key:"append",fn:function(){return [_c('v-icon',{staticClass:"BSW-select-input__clear",on:{"click":function($event){return _vm.$emit('clear')}}},[_vm._v("clear")])]},proxy:true}:null,(_vm.itemValue)?{key:"item",fn:function({ item }){return [_c('div',{staticClass:"BSW-select-input__item"},[(item[_vm.itemValue] === 2514)?_c('v-divider',{staticClass:"BSW-select-input__item-divider"}):_vm._e(),_c('div',{staticClass:"BSW-select-input__item-content"},[_c('span',[_vm._v(" "+_vm._s(item[_vm.itemText])+" "),(item[_vm.itemIcon])?_c('v-icon',[_vm._v(_vm._s(item[_vm.itemIcon]))]):_vm._e()],1),_c('span',{staticClass:"BSW-select-input__item-check",class:{
                active:
                  item[_vm.itemValue] === _vm.value ||
                  (_vm.multiple && _vm.value?.includes(item[_vm.itemValue])),
              }},[(
                  item[_vm.itemValue] === _vm.value ||
                  (_vm.multiple && _vm.value?.includes(item[_vm.itemValue]))
                )?_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-check-bold")]):_vm._e()],1)])],1)]}}:null,(_vm.multiple || _vm.withBg)?{key:"selection",fn:function({ item }){return [_c('v-chip',{staticClass:"BSW-select-input__chip",attrs:{"small":""}},[_c('span',{staticClass:"BSW-select-input__chip__text",class:{ wrapText: _vm.wrapText }},[_vm._v(_vm._s(item.name))])])]}}:null,{key:"append-outer",fn:function(){return [(_vm.showDescription)?_c('InputDescription',{staticClass:"BSW-select-input__description",attrs:{"content":_vm.info,"title":_vm.label}}):_vm._e()]},proxy:true}],null,true)},'v-autocomplete',attrs,false),on))]}}])},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.label))]),_c('ul',{staticClass:"BSW-select-input__tooltip-list"},_vm._l((_vm.items),function(item,i){return _c('li',{key:i},[_vm._v(_vm._s(item.name))])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }