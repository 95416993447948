import api from "@/shared/services/api";
import store from "@/store/app";
export default async function getMainInfo() {
    return api
        .get(process.env.VUE_APP_INFO_2_URL)
        .then((res) => {
        if (res.data.status === "success") {
            store.commit("writeRegions", res.data.data.regions);
            store.commit("writeBanksLogos", res.data.data.bank_logos);
            store.commit("writeBankAdvertises", res.data.data.bank_advertises);
            if (res.data.data.bank_advertises) {
                let topBanks = {};
                Object.entries(res.data.data.bank_advertises).forEach((ad) => {
                    if (ad[1].is_gtop) {
                        topBanks[ad[0]] = {
                            bank_id: ad[0],
                            tagline_for_top: ad[1].gtop_message,
                        };
                    }
                });
                store.commit("common/writeTopBanks", topBanks);
            }
        }
    })
        .catch((e) => {
        store.dispatch("openAlertError", e);
    });
}
