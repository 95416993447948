export const getDefaultAppState = () => {
    return {
        window: null,
        /** Флаг для отображения оверлея */
        overlay: false,
        /** Массив объектов регионов */
        regions: [],
        banksLogos: {},
        bankAdvertises: {},
        info: {
            useRegionForRegistration: true,
            useEmployeeTypeForRegistration: true,
            userTypeItems: [],
        },
        /** Текущий элемент левого меню */
        menuItem: null,
        isRegistered: false,
        isRegisteredEvent: false,
        isCabinet: false,
        withoutNav: false,
        banksList: [],
        timepadList: [],
        influence: {
            id: 0,
            timer: 0,
            delayDemonstration: 0,
            newsId: undefined,
            emailLabel: "",
            isLink: false,
            linkText: "",
            linkUrl: "",
            isPhoneInput: false,
            isEmailInput: false,
            phoneLabel: "",
            image: "",
            imageMobile: "",
            influenceShow: false,
            disabled: false,
        },
        faqList: {},
        grade: 0,
        orderWidgetTypes: [],
    };
};
export const state = getDefaultAppState();
