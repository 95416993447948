export const mutations = {
    /** Запись текущей ширины экрана
     * @param state хранилище
     * @param width передаваемое значение ширины
     */
    writeWindow(state, width) {
        state.window = width;
    },
    /** Переключение флага отображения оверлея
     * @param state хранилище
     * @param bool булевое значение */
    toggleOverlay(state, bool) {
        state.overlay = bool;
    },
    /** Запись массива объектов регионв в хранилище
     * @param state хранилище
     * @param data массив объектов регионов */
    writeRegions(state, data) {
        state.regions = data;
    },
    writeBanksLogos(state, data) {
        state.banksLogos = data;
    },
    writeBankAdvertises(state, data) {
        if (data) {
            state.bankAdvertises = data;
        }
    },
    writeInfo(state, data) {
        state.info = data;
    },
    writeGrade(state, grade) {
        state.grade = grade;
    },
    /** Запись текущего элемента меню */
    writeMenuItem(state, data) {
        state.menuItem = data;
    },
    setIsRegistered(state, data) {
        state.isRegistered = data;
    },
    setIsRegisteredEvent(state, data) {
        state.isRegisteredEvent = data;
    },
    setIsCabinet(state, payload) {
        state.isCabinet = payload;
    },
    setWithoutNav(state, payload) {
        state.withoutNav = payload;
    },
    writeInfluence(state, data) {
        if (data?.data) {
            const newInfluence = {
                timer: data.timer,
                id: data.data.id,
                delayDemonstration: data.data.delay_demonstration,
                emailLabel: data.data.email_label,
                image: data.data.image,
                imageMobile: data.data.image_mobile,
                isEmailInput: data.data.is_email_input,
                isLink: data.data.is_link,
                isPhoneInput: data.data.is_phone_input,
                linkText: data.data.link_text,
                linkUrl: data.data.link_url,
                newsId: data.data.news_id,
                phoneLabel: data.data.phone_label,
                influenceShow: !state.influence.disabled,
            };
            state.influence = newInfluence;
        }
        else {
            state.influence = {
                influenceShow: false,
            };
        }
    },
    writeInfluenceField(state, data) {
        Object.keys(data).map((key) => {
            if (key in state.influence) {
                state.influence[key] = data[key];
            }
        });
    },
    writeTimepadList(state, data) {
        state.timepadList = data;
    },
    writeFaqList(state, data) {
        const categories = Object.values(data["categories"]);
        for (let i = 0; i < categories.length; i++) {
            state.faqList[categories[i]] = Object.values(data["list"])[i];
        }
    },
    writeWidgetOrderTypes(state, data) {
        state.orderWidgetTypes = data;
    },
};
