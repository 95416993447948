import Cookies from "js-cookie";
export const GetWidgetToken = () => {
    const isDev = process.env["VUE_APP_IS_DEV"] === "true";
    if (isDev) {
        return process.env.VUE_APP_TOKEN;
    }
    else {
        const token = document.getElementById("widgetConfig").dataset.wid;
        return token ?? "";
    }
};
export const GeUserWidgetToken = () => {
    let token = document.getElementById("widgetConfig").dataset.wcrutbs;
    if (!token) {
        token = Cookies.get("wcrutbs") ?? "";
    }
    return token;
};
